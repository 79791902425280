$cab-light-gray: #f4f4f4;
$cab-gray: #d4d4d4;
$cab-dark-gray: #707070;

$primary-cabas: #0072bc;
$accent-cabas: #f37021;

$selected: #e8e9ea;
$primary-cab-group: #30383b;
$error: #c20114;
$warning: #ffba08;
$info: #00498b;
$message: #09814a;