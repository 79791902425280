@import "../../cab-colors.scss";

.container {
	display: flex;
	flex-direction: column;
	width: 80%;
	background-color: #F1F9FE;
	border-radius: 5px;
	border: solid transparent;
	padding: 19px;
	margin: auto;
	margin-bottom: 27px;
	margin-top: 18px;
}

.text {
	margin: 5px;
	color: black;
	line-break: auto;
	text-decoration: none;
	font-weight: bold;
}

.linkText {
	margin-left: 5px;
	color: $primary-cabas;
	line-break: auto;
	text-decoration: none;
	font-weight: bold;
}

.linkText:hover {
	color: $accent-cabas;
}