@import "../../cab-colors.scss";

* {
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: small;
}

.baseContainer {
	display: block;
	margin: 70px auto;
	background-color: $primary-cab-group;
	border-radius: 0.2em;
	border: solid transparent;
	padding-top: 1.3em;
	height: 80%;
	width: 470px;
	box-shadow: 0 5px 3px 1px grey;
}