@import "../../cab-colors.scss";

.Content {
	padding: 3px 2px;
	width: auto;
	color: white;
	margin-left: auto;
	margin-right: auto;
}

.contentContainer {
	padding: 3px 2px;
	width: auto;
	color: white;
	margin-left: auto;
	margin-right: auto;
}

.button {
	background-color: $primary-cabas;
	color: white;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	width: 90%;
	line-height: 1.5em;
	/* important */
	border-radius: 5px;
}

.buttonBase {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.headline {
	display: flex;
	justify-content: left;
	padding-left: 2.1em;
}

.headlineInstallation {
	display: flex;
	justify-content: left;
	padding-left: 2.1em;
	padding-top: 12px;
}

.downloadbutton {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $message;
	width: 90%;
	padding: 0.375rem 0.75rem;
	line-height: 1.5em;
	border-radius: 5px;
	color: white;
}

.linkButton {
	color: white;
	background-color: $primary-cab-group;
	margin-top: 14px;
	text-decoration: underline;
}

.linkButton:hover {
	color: $primary-cabas;
}

.link {
	display: flex;
	padding-top: 0.285em;
	justify-content: center;
	align-items: center;
}