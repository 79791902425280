@import "../../cab-colors.scss";

.downloadpageheader
{
	color: #DEDEDE;
	font-size: larger;
	font-weight: 700;
	line-break: auto;
	margin: auto;
}

button {
	background-color: #EFEFEF;
	border: solid 1px #333333;
	cursor: pointer;
	padding: 0 20px;
}

.top {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: large;
	margin-right: 22px;
	margin-top: 0.8em;
	margin-bottom: 1.2em;
}

.flagContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.8em;
}

.baseContainer {
	display: block;
	margin: 70px auto;
	background-color: $primary-cab-group;
	border-radius: 0.2em;
	border: solid transparent;
	padding-top: 1.3em;
	height: 80%;
	width: 470px;
	box-shadow: 0 5px 3px 1px grey;
}