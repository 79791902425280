.image {
	padding: 2.5px;
}

.image:hover, .imageSvg:hover {
	cursor: pointer;
}
.flagContainer{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.row
{
	display: flex;
	flex-direction: row;
}
.imageSvg
{
	width: 74px;
	height: 39px;
	padding: 2.5px;
}