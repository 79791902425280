@import "../../cab-colors.scss";

.content {
	padding: 10px 2px;
	width: auto;
	color: white;
	position: relative;
	margin-left: auto;
	margin-right: auto;
}

.paragraph {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.8em;
	line-break: normal;
	text-align: justify;
	line-height: 1.2;
}

.disclaimerHeader {
	font-weight: bold;
	margin: auto;
	font-size: larger;
	color: white;
	line-break: auto;
	text-align: center;
}

.linkBack {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1.6em;
}

.baseContainer {
	display: block;
	margin: 70px auto;
	background-color: $primary-cab-group;
	border-radius: 0.2em;
	border: solid transparent;
	padding-top: 1.3em;
	height: 80%;
	width: 470px;
	box-shadow: 0 5px 3px 1px grey;
}