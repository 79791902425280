.h1style{
	font-weight: 900;
	font-size: 2em;
}
.h2style
{
	font-weight: 600;
		font-size: 1.2em;
}
.h3style{
	font-weight: 700;
	font-size: 1em;
}

.contactInfo
{
	display: flex;
	flex-direction: column;
}
.contactInfoP
{
	margin: 0.3em 0 0.3em 0;
}